import { set } from "tiny-cookie";

class LayerCounter extends HTMLElement {
    connectedCallback(): void {
        const layerState = this.querySelector("#camp-layer-cookie-content")?.textContent;
        const campState = this.querySelector("#camp-state-cookie-content")?.textContent;
        if (layerState) {
            set("entd_layer", layerState.trim(), {
                domain: window.location.hostname,
                expires: "1Y",
                path: "/",
            });
        }
        if (campState) {
            set("camp_state", campState.trim(), {
                domain: window.location.hostname,
                expires: "7D",
                path: "/",
            });
        }
    }
}

customElements.define("camp-layer-counter", LayerCounter);
